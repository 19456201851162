.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body {
  /* width: 1900px; */
  margin: 0px auto;
  background-color: #fff; /* #f0f2f5; */
}

.root {
  height: 100%;
}

.login-container {
  width: 400px;
  height: 400px;
  /* background-color: red; */
  
  position: absolute;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  
  margin: auto;
  padding: 50px;
  box-shadow: 0px 0px 10px 3px #C2CAD1;
}

.login-header-image {
  width: 100%;
  height: 100px;
  background-image: url("./images/ca-logo.png");
  background-size: contain;
  background-repeat: no-repeat;  
  background-position: center;
  position: relative;
  cursor: pointer;
}

.login-header-image-small {
  width: 100%;
  height: 100px;
  background-image: url("./images/ca-logo-small.png");
  background-size: contain;
  background-repeat: no-repeat;  
  background-position: center;
}

.login-header-version {
  position: absolute;
  right: 10px;
  top: 10px;
  color: gray;
  font-size: 10px;
  font-weight: 300;
}

.filter-option-disabled {
  /* box-shadow: 0px 0px 5px 1px #c2cad1; */
  padding: 10px;
  border: 0px solid white;
  background-color: #EDF1F4;  
  width: 230px;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
}

.filter-option-enabled {
  /* box-shadow: 0px 0px 5px 1px #c2cad1; */
  /* border: 1px solid black; */
  padding: 10px;
  /* background-color: #EDF1F4; */
  /* width: 1620px; */
  display: block;
  margin-right: 10px;
  margin-bottom: 10px;
}

.filter-option-title {
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 600;  
}

.filter-option-title-inline {
  font-size: 12px;
  font-weight: 600;
  width: 150px;
  display: inline-block;
}

.chart-container {
  width: 100%; 
  float: left; 
  display: inline-block;
  border: 1px solid lightgray; 
  margin: 1px; 
  padding: 10px;
  position: relative;
  /* min-height: 615px; */
}

.main-header {
  padding: 6px;
  /* background-color: darkgrey; */
  position: sticky;
  width: 100%;
  /* margin-bottom: 300px; */
  /* padding-bottom: 300px; */
  /* display: flex; */
  top: 0px;
  z-index: 1000;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table, .ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table {
  margin: -16px -16px -16px -16px;
}

.ant-table-title {
  background-color: #e6f7ff;
  font-size: 110%;
  font-weight: 700;  
}

.drawer-field-title {
  font-size: 12px;
  font-weight: 700;
}

.drawer-field-error {
  font-size: 12px;
  font-weight: 400;
  color: red;
}

.dropzone-area {
  padding: 50px;
  border: 1px dashed black;  
}

.date-title-day-number {
  position: absolute;
  top: 3px;
  left: 5px;
  color: grey;
  font-size: 16px;
  font-weight: 500;
  /* border: 1px solid red; */
  /* padding: 5px; */
  /* border-radius: 30px; */
  /* width: 50px; */
  /* text-align: center; */
  /* height: 40px; */
  /* box-shadow: 0px 0px 7px 0px #e5e5e5;*/  
}

.date-title-day-number-2 {
  color: grey;
  font-size: 16px;
  font-weight: 500;
  /* border: 1px solid red; */
  /* padding: 5px; */
  /* border-radius: 30px; */
  /* width: 50px; */
  /* text-align: center; */
  /* height: 40px; */
  /* box-shadow: 0px 0px 7px 0px #e5e5e5;*/  
}

.date-title-month-year {
  position: absolute;
  top: 3px;
  right: 5px;
  color: grey;
  font-size: 12px;
  font-weight: 400;
  /* border: 1px solid red; */
  /* padding: 5px; */
  /* border-radius: 30px; */
  /* width: 50px; */
  /* text-align: center; */
  /* height: 40px; */
  /* box-shadow: 0px 0px 7px 0px #e5e5e5;*/  
}

.date-title-day-name {
  padding-left: 3px;
  font-size: 11px;
  vertical-align: text-top;
  font-weight: 400;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.row-severity-warning {
  background-color: #ffffc9;
}

.row-severity-error {
  background-color: #ffd5d5;
}

.row-settings-crime-hidden {
  background-color: #efefef;
  color: #d4380d;
}

.td-empty-short {
  width: 20px;
}

.td-legend-row-title {
  width: 100px;
}

.custom-tooltip {
  border: 1px solid black;
  background-color: white;
  padding: 15px;
}

/* .ant-table.ant-table-middle .ant-table-tbody .ant-table-wrapper:only-child .ant-table */
.ant-table.ant-table-middle .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
  margin: -12px -8px -12px 0px;
}

.ant-table.ant-table-small .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
  margin: -8px -8px -8px 0px;
}

.chart2-container {
  display: block;
  width: 1550px;
  overflow:hidden;
  min-height: calc(100vh - 200px);
  position:relative;
}

.chart2-left {
  width: 1000px;
  background-color: green;
}

.chart2-right {
  position:absolute;
  left:1000px;
  top:0;
  width: 550px;
  overflow-y: auto;
  overflow-x: hidden;
  height:100%;
  background-color: lightblue;
}

.chart-2col-container {
  display: block;
  width: 1400px;
  overflow:hidden;
  min-height: calc(100vh - 200px);
  position:relative;
}

.chart-2col-left {
  /*position:absolute;
  left:opx;
  top:0px;*/
  width: 700px;
  /* background-color: green; */
  overflow-y: auto;
  overflow-x: hidden;
  height:100%;
}

.chart-2col-right {
  position:absolute;
  left:700px;
  top:0px;
  width: 700px;
  overflow-y: auto;
  overflow-x: hidden;
  /* height:100%; */
  /* background-color: lightblue; */
}

.chart3-main-container {
  /* display: inline-block; */
  /* border: 1px solid lightgray;  */
  position: relative;
  /* margin-left: 5px; */
  /* width: 1600px; */
  width: 100%;
  margin-bottom: 20px;
  /* min-height: 615px; */
}

.chart3-container {
  border-bottom: 1px solid lightgray;  
  display: block;
  width: 100%;
  /* width: 1600px; */
/*  overflow:hidden; */
  /* min-height: calc(100vh - 200px); */
  position:relative;
  /* background-color: blueviolet; */
}

.chart3-col-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;  
}

.chart3-col-title-row {
  margin: 20px;
}

.chart3-col-left {
  padding: 10px;
  /*position:absolute;
  left:opx;
  top:0px;*/
  width: 300px;
  /* background-color: green; */
  display: inline-block;
  vertical-align: top;
  /* background-color: chocolate; */
}

.chart3-col-right {
  /* position:absolute;
  left:200px;
  top:0px; */
  /* border-left: 1px solid lightgray; */
  /* display: inline-block; */
  width: 100%;
  /* background-color: crimson; */
  /* overflow-y: auto;
  overflow-x: hidden; */
  /* height:100%;  */
  /* background-color: lightblue; */
}

.ant-layout-sider-light {
  background-color: #f0f2f5;
}

.ant-layout-sider-light .ant-menu {
  background-color: #f0f2f5;
}

.ant-layout {
  background-color: #fff;
}

.ant-page-header-heading {
  height: 50px;
}

.incident-comment-row-title {
  font-weight: 600;
  width: 100px;
  border-bottom: 1px dotted lightgrey;
}

.incident-comment-row-data {
  border-bottom: 1px dotted lightgrey;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
